html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.Toastify__progress-bar--default {
  background: #dc0c05 !important;
}

.Toastify__toast-body {
  color: black;
}

.white {
  background: white;
}

/* .row-expand-slide-appear-active {
  transition: none !important;
}

.row-expand-slide-exit-active {
  transition: none !important;
} */
